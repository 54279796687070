import React from "react";
// Components
import Main from "./Main";
import HomeTickers from "./HomeTickers";
import Footer from "./Footer";
// Hooks
import { useMarketFetch } from "../Hooks/useMarketFetch";
import TickerCard from "./TickerCard";
// Images

export const Home = () => {
    const { market, loading, error } = useMarketFetch();

    // console.log(market.data[0].symbol)
    return (
        <>
            <Main />
            <Footer />
            <HomeTickers header="Tickers">
                {market.data.map(ticker => (
                    <TickerCard
                        key={ticker.id}
                        name={ticker.slug}
                        symbol={ticker.symbol}
                        price={ticker.metrics.market_data.price_usd}
                    />
                ))}
            </HomeTickers>
        </>
    )
}

export default Home;
