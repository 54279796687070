import { useEffect, useState } from "react";
// API
import API from "../API";

const initialMarket = {
    status: {},
    data: []
};

export const useMarketFetch = () => {
    const [market, setMarket] = useState(initialMarket);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchMarket = async () => {
      try {
          setError(false);
          setLoading(true);

          const market = await API.fetchMarket();

          setMarket(() => ({
              ...market,
              data: [...market.data]
          }));
      } catch (error) {
          setError(true);
      }
      setLoading(false)
    };

    // Initial render
    useEffect(() => {
        fetchMarket();
    }, []);

    return { market, loading, error };
};
