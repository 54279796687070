import React from "react";
// Styles
import { Wrapper, Content } from "./HomeTickers.styles";

const HomeTickers = ({ header, children }) => (
    <Wrapper>
        <h1>{header}</h1>
        <Content>{children}</Content>
    </Wrapper>
);

export default HomeTickers;
