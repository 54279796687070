import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Content = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarlosImg = styled.img`
  width: 300px;
  animation: breath 4s linear infinite;
  
  @keyframes breath {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

