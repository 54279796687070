import React, { useEffect } from "react";
// Components
import Home from "./components/Home";
// Styles
import { GlobalStyle } from "./GlobalStyle";
import {Header} from "./components/Header";
import Footer from "./components/Footer";
// Google Analytics
import ReactGa from 'react-ga';

const App = () => {

    useEffect(() => {
        ReactGa.initialize('G-067157PPLQ')

        // To Report Page View
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

  return (
      <div className="App">
        <Header />
        <Home />
        <GlobalStyle />
      </div>
  );
}

export default App;
