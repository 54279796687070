import React from "react";
// Styles
import { Wrapper, Content} from "./TicketCard.styles";

const TickerCard = ({ name, symbol, price}) => (
    <Wrapper>
        <h1>{symbol}</h1>
        <p>{name}</p>
        <p>{price}</p>
    </Wrapper>
)

export default TickerCard;
