import styled from "styled-components";

export const Wrapper = styled.div`
  color: white;
  background-color: rgba(105,105,255,0.9);
  box-shadow: 1px 1px 6px rgba(0,0,0,0.6), -1px -1px 6px rgba(255,255,255,0.8), inset 1px 1px 3px rgba(0,0,0,0.6), inset -1px -1px 3px rgba(175,175,255,0.8);
  border-radius: 20px;
  border: 5px solid black;
  padding: 0 10px;
  
  p, h1 {
    color: white;
  }
  
`;

export const Content = styled.div``;
