import React from "react";

import { Wrapper, Content } from "./Main.Styles";

const Main = () => {

    return (
        <Wrapper>
            <Content>
                <h1>Under construction. Come back Soon!</h1>
            </Content>
            <p>NFTs</p>
            <p>Crypto Tracker</p>
            <p>Trending</p>
            <h5>Working on future updates. Trying to catch up. <b>#layer2</b> <b>#beYourOwnBank</b> <b>#seeYouInTheMetaverse</b> <b>#VIBEZ</b> <b>#WEB3</b></h5>
            <h6>***Might start semi-daily(more like weekly probably) updating on my twitter. <a href="https://twitter.com/cvrlxsguzman">@cvrlxsguzman</a></h6>
        </Wrapper>
    )
}

export default Main;
