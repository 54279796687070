import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: rgba(105,105,255,0.03);
  h1 {
    text-align: center;
  }
`;

export const Content = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 20px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr
  ));
  grid-gap: 2rem;
`;

export const Ticker = styled.div``;
