import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Logo
import Logo from '../../Images/pixelThree14Logo-noPadding-noBackground-720x188.png';

import { Wrapper, Content, LogoImg, H1 } from "./Header.styles";

export const Header = () => {

    return (
        <Wrapper>
            <Content>
                <LogoImg src={Logo} alt="Logo" />
                <H1>Hello,</H1>
            </Content>
        </Wrapper>
    )
}
