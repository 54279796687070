import {MARKET_URL} from "./config";

const defaultConfig = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
};

const apiSettings = {
    fetchMarket: async () => {
        return await ((await fetch(MARKET_URL)).json());
    },
}

export default apiSettings;
