import React from "react";
// Carlos Image
import Carlos from "../../Images/pixelCarlosV2Final2.png";
import MetaCarlos from "../../Images/pixelSpaceBackground-pixelCarlosHeadset-coloredSkin-720x720.png"

import { Wrapper, Content, CarlosImg } from "./Footer.styles";

const Footer = () => {

    return (
        <Wrapper>
            <Content>
                {/*<CarlosImg src={Carlos} alt="Carlos Img" />*/}
                <CarlosImg src={MetaCarlos} alt="Meta Carlos" />
            </Content>
        </Wrapper>
    )
}

export default Footer;
