import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  color: black;
  text-align: center;
  
  p {
    color: black;
    width: fit-content;
    margin: 5px auto;
  }

  a, p {
    padding: 7px;
    border: 2px solid black;
  }
  h6 a {
    display: inline-block;
    margin: 5px;
  }
  a:hover {
    border-top: none;
    border-bottom: none;
  }
`;

export const Content = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  p {
    color: black;
  }
`;

