import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: white;
  padding: 20px 0;
`;

export const Content = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: var(--maxWidth);
  padding: 20px;
  margin: 0 auto;
`;

export const LogoImg = styled.img`
  height: 65px;
  
  @media screen and (max-width: 720px) {
    height: 40px;
  }
  @media screen and (max-width: 420px) {
    height: 35px;
  }
`;

export const H1 = styled.h1`
  font-size: var(--fontMed);
  font-family: 'Mohave', sans-serif;
  border-bottom: 1px solid black;
`;

