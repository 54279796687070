// Configuration for Messari API
// https://messari.io/api

const API_URL = 'https://data.messari.io/api/v2/assets';

const MARKET_URL = `${API_URL}?fields=id,slug,symbol,metrics/market_data/price_usd`;
const NEWS_URL = `https://data.messari.io/api/v2/news`;


export {
    API_URL,
    MARKET_URL,
    NEWS_URL
};
